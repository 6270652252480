<template>
  <div
    class="mb-0"
    :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-8' : 'px-4'"
  >
    <!-- ===== DIALOG FILTER ==== -->
    <v-dialog v-model="dialog.filtered" scrollable persistent max-width="550px">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 text-first d-flex mb-4"
          style="background-color: #d31145;"
        >
          <span class="white--text">
            Filter
          </span>
        </v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#d31145"
            elevation="0"
            :disabled="process.form"
            :loading="process.form"
            class="white--text text-capitalize"
            @click="toFilter()"
          >
            Filter
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.form"
            @click="dialog.filtered = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG DETAIL ==== -->
    <v-dialog
      v-model="dialog.detail"
      fullscreen
      persistent
      scrollable
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="#d31145">
          <v-toolbar-title :class="$vuetify.breakpoint.name === 'xs' ? 'body-2' : ''"
            >Detail Produk Terjual di Kategori
            <span class="font-weight-bold">({{ category_name }})</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text dark fab @click="dialog.detail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5" :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''" style="height: 100vh">
          <!-- <div v-if="$vuetify.breakpoint.name !== 'xs'">
            <v-simple-table>
              <template v-slot:default>
                <thead v-if="!process.run && detail.length > 0">
                  <tr>
                    <th style="width: 20%">Nama</th>
                    <th style="width: 5%">Kode Produk</th>
                    <th style="width: 10%" class="text-center">Total Terjual</th>
                    <th style="width: 13%" class="text-right">
                      Total Harga Jual (Rp)
                    </th>
                    <th style="width: 13%" class="text-right">
                      Total Harga Beli (Rp)
                    </th>
                    <th style="width: 13%" class="text-right">
                      Total Bruto (Rp)
                    </th>
                    <th style="width: 13%" class="text-right">
                      Total Diskon (Rp)
                    </th>
                    <th style="width: 13%" class="text-right">
                      Total Netto (Rp)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in detail" :key="index">
                    <td class="text-second">
                      {{ item.product_name }}
                    </td>
                    <td class="text-second">
                      {{ item.product_code }}
                    </td>
                    <td class="text-second text-center">
                      {{ item.total_product_qty | num_format }}
                    </td>
                    <td class="text-second text-right">
                      {{ item.total_product_selling_price }}
                    </td>
                    <td class="text-second text-right">
                      {{ item.total_product_purchase_price }}
                    </td>
                    <td class="text-second text-right">
                      {{ item.total_bruto_price }}
                    </td>
                    <td class="text-second text-right">
                      {{ item.total_discount_nominal }}
                    </td>
                    <td class="text-second text-right">
                      {{ item.total_nett_price }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div> -->

          <div>
            <v-data-table
              :headers="headers_detail"
              :items="detail"
              :disable-sort="true"
              hide-default-footer
              :items-per-page="10000000000"
              class="elevation-1 hide_sort"
            >
              <template v-slot:[`item.product_name`]="{ item }">
                {{ item.product_name }}
              </template>
              <template v-slot:[`item.product_code`]="{ item }">
                {{ item.product_code }}
              </template>
              <template v-slot:[`item.total_product_qty`]="{ item }">
                {{ item.total_product_qty }}
              </template>
              <template v-slot:[`item.total_product_selling_price`]="{ item }">
                {{ item.total_product_selling_price }}
              </template>
              <template v-slot:[`item.total_product_purchase_price`]="{ item }">
                {{ item.total_product_purchase_price }}
              </template>
              <template v-slot:[`item.total_bruto_price`]="{ item }">
                {{ item.total_bruto_price }}
              </template>
              <template v-slot:[`item.total_discount_nominal`]="{ item }">
                {{ item.total_discount_nominal }}
              </template>
              <template v-slot:[`item.total_nett_price`]="{ item }">
                {{ item.total_nett_price }}
              </template>
            </v-data-table>
          </div>

        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- ==== DATA LAPORAN KATEGORI ==== -->
    <div class="my-5">
      <div class="text--secondary mb-3" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 font-weight-bold' : 'title'">
        Data Rekap Penjualan Per kategori
      </div>
      <div class="mb-3 d-flex align-center flex-wrap">

        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'xls'"
          :btnText="'Export Excel'"
          :btnIcon="'mdi-microsoft-excel'"
          :btnColor="'teal'"
          :url="`franchise/report/recap/transaction/product_category/export_excel?recap_job_id=${$route.params.id}`"
          :title="'Ringkasa Penjualan Per Kategori'"
          ref="base-export-excel"
          v-on:onClickExport="responseExport"
        />
        <Export
          class=" mr-2"
          :disabled="$store.state.loading"
          :file_type="'pdf'"
          :btnText="'Export PDF'"
          :btnIcon="'mdi-file-pdf-box'"
          :btnColor="'#f40f02'"
          :url="`franchise/report/recap/transaction/product_category/export_pdf?recap_job_id=${$route.params.id}`"
          :title="'Ringkasa Penjualan Per Kategori'"
          ref="base-export-pdf"
          v-on:onClickExport="responseExport"
        />


        <v-btn
          depressed
          :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
          :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
          elevation="0"
          :disabled="process.run"
          color="#aaa9ad"
          class="white--text text-capitalize"
          @click="$refs['base-table'].isFilterVisible = true"
        >
          <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
          <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
        </v-btn>
      </div>

      <!-- ==== FILTER STATUS ==== -->
      <div v-if="status.date || status.kategori || status.store">
        Pencarian :
        <v-chip
          v-if="status.date"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('date')"
        >
          Tanggal: {{ filterdate.start | dateFull }} - {{ filterdate.end | dateFull }}
        </v-chip>
        <v-chip
          v-if="status.store"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('store')"
        >
          Outlet: {{ filternamestore }}
        </v-chip>
        <v-chip
          v-if="status.kategori"
          class="ma-2"
          close
          :small="$vuetify.breakpoint.name === 'xs' ? true : false"
          @click:close="handleCloseChips('category')"
        >
          kategori: {{ filternamekategori }}
        </v-chip>
      </div>

      <!-- ==== LIST DATA SUMMARY PENJUALAN Per Produk ==== -->
      <v-expansion-panels flat accordion v-model="panel" focusable>
        <v-expansion-panel expand v-model="panel">
          <v-expansion-panel-header
            class="title text--secondary"
            style="font-size:1rem !important;"
            >Rincian Penjualan Per Kategori</v-expansion-panel-header
          >
          <v-expansion-panel-content
            class="pt-5 mb-2"
            style=" box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; border-radius:5px;"
          >
            <v-row>
              <v-col cols="6" sm="4" :class="$vuetify.breakpoint.name === 'xs' ? 'px-1' : ''"
                v-for="(item, key) in total" :key="key">
                <v-card class="box-shadow border-radius pa-2">
                  <v-card-actions class="pt-2 px-0">
                    <div style="width:100%">
                      <v-card-title
                        class="font-weight-bold text--secondary px-0 py-0 pb-1"
                        :style="$vuetify.breakpoint.name === 'xs' ? 'font-size: 11px; line-height: 1; height: 30px' : ''"
                        :class="$vuetify.breakpoint.name === 'xs' ? '' : 'body-2'"
                      >
                        {{ item.label }}
                        <v-spacer></v-spacer>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="#d31145"
                              v-if="item.note.title != ''"
                              v-on="on"
                              v-bind="attrs"
                              size="20"
                              >mdi-help-circle</v-icon
                            >
                          </template>
                          <div class="d-flex flex-column">
                            <span class="font-weight-bold"><b>{{item.note.title}}</b></span>
                            <span>{{ item.note.content }}</span>
                          </div>
                        </v-tooltip>
                      </v-card-title>
                      <v-card-text :class="$vuetify.breakpoint.name === 'xs' ? 'title text-second px-0' : 'headline text-second px-0'">
                        {{ item.value }}
                      </v-card-text>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- ==== LIST DATA KATEGORI TERLARIS ==== -->
      <div v-show="process.run && list.length < 1">
        <v-skeleton-loader
          :loading="process.run"
          transition="scale-transition"
          :tile="false"
          type="table-tbody"
        >
        </v-skeleton-loader>
      </div>
      <div>
        <!-- <v-data-table
          flat
          v-if="list.length > 0"
          fixed-header
          height="calc(100vh - 425px)"
          class="mt-5 laporan"
          :headers="headers"
          :loading="loading"
          hide-default-footer
          disable-pagination
          :options.sync="options"
          :items="list">
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td class="text-center">
                  <v-btn
                    small
                    text
                    min-width="30"
                    class="text-capitalize orange--text text--darken-1 pa-0 mr-1"
                    @click="toDetail(item)">
                    <v-icon small>mdi-information-outline</v-icon>
                  </v-btn>
                </td>
                <td class="text-left" >{{ item.sales_order_detail_category_name }}</td>
                <td class="text-center" >{{ item.total_qty | num_format }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table> -->

        <base-table
          v-on:onResetFilter="resetFilter"
          v-on:onRetrieveDataStart="responseDataStart"
          v-on:onRetrieveDataEnd="responseDataEnd"
          v-show="displayBaseTable"
          :isExternalFilterButton="true"
          :isFilterOnHeader="true"
          ref="base-table"
          dateType="date"
          dateFormat="DD MMMM YYYY"
          customHeight="calc(100vh - 350px)"
          :filterHeader="filterHeader_salesCategory"
          itemKey="id"
          :single-select="singleSelect"
          :headers="headers"
          :retrieveDataURL="`franchise/report/recap/transaction/product_category?recap_job_id=${$route.params.id}`"
          filterType="laporan"
        >
          
          <template v-slot:[`item.aksi_table`]="{ item }">
            <v-btn
              small
              text
              min-width="30"
              class="text-capitalize orange--text text--darken-1 pa-0 mr-1"
              @click="toDetail(item)"
            >
              <v-icon small>mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.category_name`]="{ item }">
            {{ item.category_name }}
          </template>
          <template v-slot:[`item.total_product_qty`]="{ item }">
            {{ item.total_product_qty | num_format }}
          </template>

          <template v-slot:[`item.total_product_selling_price`]="{ item }">
            {{ item.total_product_selling_price }}
          </template>

          <template v-slot:[`item.total_product_purchase_price`]="{ item }">
            {{ item.total_product_purchase_price }}
          </template>

          <template v-slot:[`item.total_bruto_price`]="{ item }">
            {{ item.total_bruto_price }}
          </template>

          <template v-slot:[`item.total_discount_nominal`]="{ item }">
            {{ item.total_discount_nominal }}
          </template>

          <template v-slot:[`item.total_nett_price`]="{ item }">
            {{ item.total_nett_price }}
          </template>

          <template v-slot:filterTitle>
            Data Penjualan Per kategori
          </template>

          <template v-slot:customFilter>
            <v-row>
              <v-col cols="12">
                <VueCtkDateTimePicker
                  v-model="filterdate"
                  label="Pilih Tanggal"
                  :range="true"
                  noClearButton
                  color="#d31145"
                  format="YYYY-MM-DD"
                  formatted="LL"
                  locale="id"
                  :max-date="todays"
                  :custom-shortcuts="[
                    { key: 'today', label: 'Hari ini', value: 'day' },
                    { key: 'thisWeek', label: 'Minggu ini', value: 'isoWeek' },
                    { key: 'lastWeek', label: 'Minggu lalu', value: '-isoWeek' },
                    { key: 'last7Days', label: '7 hari terakhir', value: 7 },
                    { key: 'last30Days', label: '30 hari terakhir', value: 30 },
                    { key: 'thisMonth', label: 'Bulan ini', value: 'month' },
                    { key: 'lastMonth', label: 'Bulan lalu', value: '-month' },
                    { key: 'thisYear', label: 'Tahun ini', value: 'year' },
                    { key: 'lastYear', label: 'Tahun lalu', value: '-year' },
                  ]"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  item-text="store_name"
                  item-value="store_id"
                  :items="outlet"
                  outlined
                  clearable
                  dense
                  color="#d31145"
                  label="Pilih Outlet"
                  v-model="filterstore"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  item-text="title"
                  item-value="title"
                  :items="category"
                  outlined
                  clearable
                  dense
                  color="#d31145"
                  label="Kategori"
                  v-model="filterkategori"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </template>
        </base-table>
      </div>

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-show="emptyData" />
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy, exportExcel } from "@/service/Axios";
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import moment from "moment";
import Export from "@/components/Export";

export default {
  data() {
    return {
      panel: "",
      todays: new Date().toISOString().substr(0, 10),
      /**
       * DATA COMPONENT BASETABLE
       */
      displayBaseTable: false,
      emptyData: false,
      singleSelect: true,
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },
      headers: [
        { text: "Aksi", align: "center", width: 70, sortable: false, value: "aksi_table" },
        {
          text: "Kategori Produk",
          align: "left",
          value: "category_name",
          width: 150,
        },
        { text: "Nama Outlet", align: "left", value: "store_name", width: 150 },
        { text: "Total Qty Produk Terjual", align: "center", value: "total_product_qty", width: 150 },
        { text: "Total Harga Jual (Rp)", align: "end", value: "total_product_selling_price" },
        { text: "Total Harga Beli (Rp)", align: "end", value: "total_product_purchase_price" },
        { text: "Total Bruto (Rp)", align: "end", value: "total_bruto_price" },
        { text: "Total Diskon (Rp)", align: "end", value: "total_discount_nominal" },
        { text: "Total Netto (Rp)", align: "end", value: "total_nett_price" },
      ],
      headers_detail: [
        {
          text: "Nama",
          sortable: false,
          value: "product_name",
        },
        { text: "Kode Produk", sortable: false, value: "product_code" },
        { text: "Total Terjual", sortable: false, value: "total_product_qty" },
        { text: "Total Harga Jual (Rp)", sortable: false, align: "end", value: "total_product_selling_price" },
        { text: "Total Harga Beli (Rp)", sortable: false, align: "end", value: "total_product_purchase_price" },
        { text: "Total Bruto (Rp)", sortable: false, align: "end", value: "total_bruto_price" },
        { text: "Total Diskon (Rp)", sortable: false, align: "end", value: "total_discount_nominal" },
        { text: "Total Netto (Rp)", sortable: false, align: "end", value: "total_nett_price" },
      ],
      /**
       * END DATA COMPONENT BASETABLE
       */

      category_name: "",
      filterstore: "",
      filternamestore: "",
      filterkategori: "",
      filternamekategori: "",
      filterdate: {},
      hari: new Date().toISOString().substr(0, 10),
      options: {},
      loading: false,

      selected: {
        pagination: 0,
      },
      process: {
        run: false,
        loading: false,
      },
      dialog: {
        filtered: false,
        detail: false,
      },
      filterSave: {
        date: [],
        kategori: "",
      },
      status: {
        showTimeRangePanel: false,
        date: false,
        kategori: false,
        store: false,
      },
      outlet: [],
      list: [],
      detail: [],
      total: {},
      category: [],
      pagination: {
        data: {},
        page: "",
      },
      limit: 10,
      page: 1,
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  computed: {
    filterHeader_salesCategory() {
      return [
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "custom",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
        {
          type: "",
          column: "",
          valueDefault: "",
          label: "",
        },
      ];
    },

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    selected_store_id () {
      return JSON.parse(localStorage.getItem('selected_store_id'));
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  created() {
    this.fetchCategory();
    this.fetchOutlet();
  },
  methods: {
    disabledAfterThisDay(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    async Export() {
      this.process.formExport = true;
      this.process.formExport = false;
    },

    /**
     * EVENTS
     */

    resetFilter() {
      this.$refs["base-table"].params.start_date &&
        delete this.$refs["base-table"].params.start_date;
      this.$refs["base-table"].params.end_date &&
        delete this.$refs["base-table"].params.end_date;
      this.$refs["base-table"].params.category_name &&
        delete this.$refs["base-table"].params.category_name;
      this.$refs["base-table"].params.store_name &&
        delete this.$refs["base-table"].params.store_name;

      this.filterdate = {};
      this.status.date = false;

      this.status.kategori = false;
      this.filternamekategori = "";
      this.filterkategori = "";

      this.status.store = false;
      this.filternamestore = "";
      this.filterstore = "";
      this.$refs["base-table"].retrieveData();
    },

    clearFilterChip(type) {
      switch (type) {
        case 'date':
          delete this.$refs["base-table"].params["filter[0][type]"];
          delete this.$refs["base-table"].params["filter[0][field]"];
          delete this.$refs["base-table"].params["filter[0][comparison]"];
          delete this.$refs["base-table"].params["filter[0][value]"];
          break;
        case 'category':
          delete this.$refs["base-table"].params["filter[1][type]"];
          delete this.$refs["base-table"].params["filter[1][field]"];
          delete this.$refs["base-table"].params["filter[1][comparison]"];
          delete this.$refs["base-table"].params["filter[1][value]"];
          break;
        case 'store':
          delete this.$refs["base-table"].params["filter[2][type]"];
          delete this.$refs["base-table"].params["filter[2][field]"];
          delete this.$refs["base-table"].params["filter[2][comparison]"];
          delete this.$refs["base-table"].params["filter[2][value]"];
          break;
        default:
          break;
      }

      this.$refs["base-table"].retrieveData();
      this.resetFilter();
    },

    handleCloseChips(mode) {
      switch (mode) {
        case "date":
          this.filterdate = {};
          this.status.date = false;

          // this.$refs["base-table"].retrieveData();
          this.resetFilter();
          this.clearFilterChip('date')
          break;
        case "category":
          this.status.kategori = false;
          this.filternamekategori = "";
          this.filterkategori = "";
          // this.$refs["base-table"].retrieveData();
          this.resetFilter();
          this.clearFilterChip('category')
          break;
        case "store":
          this.status.store = false;
          this.filternamestore = "";
          this.filterstore = "";
          // this.$refs["base-table"].retrieveData();
          this.resetFilter();
          this.clearFilterChip('store')
          break;
      }

      if (this.status.date ||
            this.status.kategori || 
            this.status.store) {
        
      } else {
        if (this.$route.query.filter !== undefined) {
          this.$router.replace({ path: `${this.$route.path}` })
        }
      }
    },

    responseDataStart() {
      this.displayBaseTable = false;
      this.emptyData = false;
      this.loading = true;
      this.process.run = true;
      let item = [];

      //check value filterheader
      if (Object.keys(this.filterdate).length != 0) {
        //get each item
        this.$refs["base-table"].params.start_date = this.filterdate.start
        this.$refs["base-table"].params.end_date = this.filterdate.end

        //assign params to item
        // this.$refs["base-table"].params["filter[0][type]"] = "date";
        // this.$refs["base-table"].params["filter[0][field]"] = "input_complete_datetime";
        // this.$refs["base-table"].params["filter[0][comparison]"] = "bet";
        // this.$refs["base-table"].params["filter[0][value]"] = this.filterdate.start+"::"+this.filterdate.end;
        //show chips
        this.status.date = true;
      }

      if (this.filterkategori != "") {
        this.$refs["base-table"].params["filter[1][type]"] = "string";
        this.$refs["base-table"].params["filter[1][field]"] = "category_name";
        this.$refs["base-table"].params["filter[1][comparison]"] = "==";
        this.$refs["base-table"].params["filter[1][value]"] = this.filterkategori;
        this.status.kategori = true;
        this.filternamekategori = this.filterkategori;
      }

      if (this.filterstore != "") {
        this.$refs["base-table"].params["filter[2][type]"] = "string";
        this.$refs["base-table"].params["filter[2][field]"] = "store_id";
        this.$refs["base-table"].params["filter[2][comparison]"] = "==";
        this.$refs["base-table"].params["filter[2][value]"] = this.filterstore;

        this.outlet.forEach((item) => {
          this.status.store = true;
          if (item.store_id == this.filterstore) {
            this.filternamestore = item.store_name;
          }
        })
      }
    },

    responseDataEnd(res) {
      // this.list = res.data.results;
      // console.log(this.list);
      this.list = res.data.results;
      
      this.fetchSummaryData(this.selected_store_id)

      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
      this.process.run = false
    },

    async fetchSummaryData (store_id) {
      let start_date = this.filterdate.start ? this.filterdate.start : ''
      let end_date = this.filterdate.end ? this.filterdate.end : ''

      const response  = await get(`franchise/report/recap/transaction/product_category/summary`, {
        params: {
          recap_job_id: this.$route.params.id,
          start_date,
          end_date,
          store_id: this.filterstore,
          category_name: this.filterkategori
        }
      })

      let res = response.data

      if (res.status == 200) {
        this.total = res.results.data
      }
    },

    responseExport() {
      //send params from base table to base export
      this.$refs["base-export-excel"].params = this.$refs["base-table"].params;
      this.$refs["base-export-pdf"].params = this.$refs["base-table"].params;

      // //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },
    /**
     * END EVENTS
     */

    fetchCategory() {
      this.process.run = true;
      get(`franchise/report/recap/transaction/master/product_category`, {
        params: {
          recap_job_id: this.$route.params.id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.process.run = false;
            this.category = res.results.data;
            // console.log(this.category);
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    fetchOutlet() {
      get(`franchise/report/recap_job/transaction/store`, {
        params: {
          id: this.$route.params.id,
          pagination_bool: false,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status === 200) {
            this.outlet = res.results.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    async toDetail(item) {
      this.category_name = item.category_name;
      // this.detail = item.detail;
      await get(`franchise/report/recap/transaction/product_category/detail`, {
        params : {
          category_id: item.category_id,
          recap_job_id: this.$route.params.id,
        }
      }).then(response => {
        let res = response.data;
        if (res.status == 200) {
          this.dialog.detail = true;
          this.detail = res.results.data
        } else {
          this.cardDetail = [];
          this.cardDetailSummary = [];
        }
      })
    },
  },
};
</script>
<style scoped>
.background-bank-cols {
  background-color: #cfd8dc;
  border: 3px solid #cfd8dc;
  border-radius: 10px;
}
</style>
